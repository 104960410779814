
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import analytics from '~/mixins.js/analytics'
extend('required', {
  ...required,
  message: 'This field is required'
})
export default {
  components: {
    ValidationObserver, ValidationProvider
  },
  mixins: [analytics],
  props: ['categories'],
  data () {
    return {
      newsletter: {
        email: null,
        message: null,
        agreement: false,
        submitted: false
      },
      loading: false,
      freeDiagram: {}
    }
  },
  async fetch () {
    this.freeDiagram = await this.$backend.diagrams.get({ name: 'free_sample' }).then(d => d.results[0])
  },
  methods: {
    async subscribeToNewsletter () {
      this.loading = true
      try {
        await this.$backend.freeProduct.post({
          email: this.newsletter.email,
          message: this.newsletter.message,
          newsletter_agreement: this.newsletter.agreement,
          terms_agreement: this.newsletter.agreement,
          diagram_id: this.freeDiagram.id
        })
        this.trackEvent('Newsletter', 'Lead', this.freeDiagram.name)
        this.newsletter.submitted = true
      } finally {
        this.loading = false
      }
    }
  }
}
